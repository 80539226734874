import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, StaticImage } from 'gatsby-plugin-image'
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import * as Icon from 'react-feather'

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {

  const { heroBg } = useStaticQuery(
    graphql`
      query {
        heroBg: file(relativePath: { eq: "home/handwriting.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const image = getImage(heroBg)
  const bgImage = convertToBgImage(image)

  return (
    <Layout>
      <Seo title="Home" />

      <BackgroundImage 
        className="homeHero" 
        Tag="div" 
        style={{
          backgroundSize: '',
          backgroundPosition: '',
          backgroundRepeat: '',
        }}
        {...bgImage} preserveStackingContext
      >
        <div className="container homeHero_content">
          <div className="homeHero_logo">
            <StaticImage src="../images/writing-bee-logo.png" alt="" width={200} />
          </div>
          <h1 className="homeHero_title h-primary h0">The Writing Bee</h1>
          <h2 className="h-light homeHero_payoff">Because all kids love writing stories!</h2>
        </div>
      </BackgroundImage>

      <div className="sectionPanel bgPrimary txt-c">
        <div className="container">
          <h2><span className="h0">A Global event</span><br /> <span className="h-light">that starts right in your classroom!</span></h2>
          <p className="txt-block-c">Writing Bee Qualifying Rounds take place in individual classrooms around the world. Classroom-based winners and top vote-getters qualify for the Writing Bee Finals and a chance to become The Writing Bee Champion!</p>
        </div>
      </div>

      <div className="sectionPanel container txt-c">
        <div className="grid">
          <h2 className="grid_s10_c">The Writing Bee will improve Your Students' Learning by Providing:</h2>
        </div>
        
        <div className="grid">
          <div className="grid_m_half grid_l_quarter homeCheckItem">
            <div className="homeCheckItem_check"><Icon.Check /></div>
            <div className="homeCheckItem_desc">
              <p className="h">Standards-based Writing Practice</p>
              <p>While creating their story, students will strengthen their use of effective techniques, descriptive details, and clear event sequences.</p>
            </div>
          </div>
          <div className="grid_m_half grid_l_quarter homeCheckItem">
            <div className="homeCheckItem_check"><Icon.Check /></div>
            <div className="homeCheckItem_desc">
              <p className="h">Personalized Writing Feedback</p>
              <p>Providing students individualized feedback on their own work is the most appropriate and effective approach to teach writing.</p>
            </div>
          </div>
          <div className="grid_m_half grid_l_quarter homeCheckItem">
            <div className="homeCheckItem_check"><Icon.Check /></div>
            <div className="homeCheckItem_desc">
              <p className="h">Online Testing Preparation</p>
              <p>It's never too soon to prepare students for the online testing environment where they'll be expected to produce their best writing.</p>
            </div>
          </div>
          <div className="grid_m_half grid_l_quarter homeCheckItem">
            <div className="homeCheckItem_check"><Icon.Check /></div>
            <div className="homeCheckItem_desc">
              <p className="h">Transferable Writing Skills</p>
              <p>Storywriting offers a plethora of transferable writing skills that will boost their ability in both fiction and non-fiction writing assignments.</p>
            </div>
          </div>
        </div>

        <div className="homeTestimony">
          <div className="homeTestimony_img">
            <StaticImage src="../images/home/d-wilson.jpg" alt="D Wilson" width={100} />
          </div>
          <div className="homeTestimony_content txt-c">
            <blockquote className="homeTestimony_q">
              The Writing Bee was the most valuable writing experience I have had with my class in many years. My students were fully engaged - they collaborated, edited, imagined, improved, and happily worked for many hours on their chapter writing. My students improved their writing skills and also learned that they love writing.  Invaluable lessons.
            </blockquote>
            <p className="homeTestimony_name txt-large mb-0">D.Wilson</p>
            <p className="mb-0">Camino Elementary School, Camino, California USA</p>
          </div>
        </div>
      </div>

      <div className="sectionPanel bgDark homeComp">
        <div className="container">
          <div className="grid">
            <div className="grid_s8_c txt-c mb-2">
              <h3 className="h-script h0">The Writing Bee Competition</h3>
              <p>The Writing Bee kicks off in schools with students participating in district or school-based Qualifying sessions. Students who qualify move on to the Finals with a chance to be crowned The Writing Bee Champion.</p>
              <StaticImage src="../images/home/how-it-works.png" alt="Writing Bee Competition Process" width={700} />
            </div>
            <div className="grid_m_half grid_l_quarter txt-c">
              <h4>1. Kick Off</h4>
              <p>Students read the Writing Bee's original story start before independently writing their version of the next chapter. Teachers have the option to provide feedback and request revisions before approving each student's work.</p>
            </div>
            <div className="grid_m_half grid_l_quarter txt-c">
              <h4>2. Qualifying Rounds</h4>
              <p>Students read the anonymous submissions of their peers in small batches and vote for their favorites. A winning piece is identified to serve as the story's middle chapter, and the process repeats to identify the final chapter.</p>
            </div>
            <div className="grid_m_half grid_l_quarter txt-c">
              <h4>3. Finals</h4>
              <p>Class chapter winners and top vote-getters qualify to compete in The Writing Bee Finals. Plus, all classroom Qualifying round participants are eligible to read and cast votes for their favorites throughout The Finals.</p>
            </div>
            <div className="grid_m_half grid_l_quarter txt-c">
              <h4>4. Winner</h4>
              <p>Writing Bee Finalists create a new story by submitting their work for review by students around the world. Only top vote-getters move on in this 'knockout style' event until the Writing Bee Champion is crowned!</p>
            </div>
          </div>
        </div>
      </div>

      <div className="sectionPanel bgPrimary">
        <div className="container txt-c">
          <div className="txt-block-c">
            <h3 className="h1">The Writing Bee Will Return</h3>
            <p className="mb-0">The Writing Bee is taking a short break and will be back bigger and better then ever!</p>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default IndexPage
